<template>
  <div
    class="container"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
    @drop.prevent="drop($event)"
  >
    <div class="drop" v-show="dropped == 2"></div>
    <!-- Error Message -->
    <div v-show="error" class="error">
      {{ error }}
    </div>

    <!-- To inform user how to upload image -->
    <div v-show="Imgs.length == 0" class="beforeUpload">
      <input
        type="file"
        style="z-index: 1"
        accept="image/*"
        ref="uploadInput"
        @change="previewImgs"
        multiple
      />
     
      <p class="mainMessage">
        {{ uploadMsg ? uploadMsg : "Click to upload or drop your images here" }}
      </p>
    </div>
    <div class="imgsPreview" v-show="Imgs.length > 0">
      <!--<button type="button" class="clearButton" @click="reset">
        {{ clearAll ? clearAll : "clear All" }}
      </button>-->
      <div class="imageHolder" v-for="(img, i) in Imgs" :key="i">
        <img :src="img.fullfilepath" />
        <span class="check-cover" style="color: white">
          <input type="checkbox" name="is_cover" v-if="img.is_cover" checked disabled />
          <input type="checkbox" name="is_cover" @click="iscover(img.id)" v-else   />
          
        </span>
        <span class="delete" style="color: white" @click="deleteconfirm(img.id)">
          <svg
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </span>
        <div class="plus" @click="append" v-if="++i == Imgs.length">+</div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import axios from '@axios'
export default {
  name: "VueUploadImages", // vue component name
   components:{
   
    draggable,
   
  },
  data() {
    return {
      error: "",
      files: [],
      dropped: 0,
      Imgs: [],
      input:{
        base64:''
      },
      pid:''
    };
  },
  props: {
    max: Number,
    id: String,
    uploadMsg: String,
    maxError: String,
    fileError: String,
    clearAll: String,
   
  },
  created(){
    this.pid = this.id;
    //console.log('pid',this.pid);
    this.getImages('');
  
  },
  methods: {
    dragOver() {
      this.dropped = 2;
    },
    deleteconfirm(id){
        this.$bvModal
          .msgBoxConfirm('Please confirm that you want to delete Image ID.'+id, {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value === true){
                 axios.delete('/property/v1/deletepropertyimages/'+id).then(res=>{
                      console.log('res',res);
                      

                      this.getImages('');

                  }).catch(()=>{
                  

                });
              //this.deleteData(id)
            }
            
          })
        
      },
    async getImages(base64i){
      //  console.log('property_id',this.id);
      let images = await axios.get('/property/v1/getpropertyimages/'+this.pid);

      let readers = [];
       this.Imgs =  [];
      if(images.data.data){
         this.Imgs = images.data.data
         if(base64i){
          let count = this.Imgs.length
          this.Imgs[count-1].fullfilepath = base64i


         }
         /* images.data.forEach((file) => {
            console.log('file',file);
           //  readers.push(this.readAsDataURL(file.fullfilepath));
            readers.push(file.fullfilepath);
          });
          
          Promise.all(readers).then((values) => {
            this.Imgs = values;
          });*/
      }
    
        /*if(images.data){
            this.lists = images.data;
        }*/
        console.log('u-images',images);
            //property/v1/getpropertyimages
    },
    dragLeave() {},
    drop(e) {
      let status = true;
      let files = Array.from(e.dataTransfer.files)
      if (e && files) {
        files.forEach((file) => {
          if (file.type.startsWith("image") === false) status = false;
        });
        if (status == true) {
          if (
            this.$props.max &&
            files.length + this.files.length > this.$props.max
          ) {
            this.error = this.$props.maxError
              ? this.$props.maxError
              : `Maximum files is` + this.$props.max;
          } else {
            this.files.push(...files);
            this.previewImgs();
          }
        } else {
          this.error = this.$props.fileError
            ? this.$props.fileError
            : `Unsupported file type`;
        }
      }
      this.dropped = 0;
    },
    append() {
      this.$refs.uploadInput.click();
    },
    readAsDataURL(file) {
      return new Promise(function (resolve, reject) {
        let fr = new FileReader();
        fr.onload = function () {
          resolve(fr.result);
        };
        fr.onerror = function () {
          reject(fr);
        };
        fr.readAsDataURL(file);
      });
    },
     iscover(image_id){
      axios.put('/property/v1/updatecover/'+this.pid+'/'+image_id).then(res=>{
        this.getImages('');
      });
     

    },
    deleteImg(index) {
      axios.delete('/property/v1/deletepropertyimages/'+id) 
      this.Imgs.splice(index, 1);
      //this.files.splice(index, 1);
      this.$emit("changed", this.files);
      this.$refs.uploadInput.value = null;
    },
    previewImgs(event) {
      if (
        this.$props.max &&
        event &&
        event.currentTarget.files.length + this.files.length > this.$props.max
      ) {
        this.error = this.$props.maxError
          ? this.$props.maxError
          : `Maximum files is` + this.$props.max;
        return;
      }
      if (this.dropped == 0) this.files.push(...event.currentTarget.files);
      this.error = "";
      this.$emit("changed", this.files);
      let readers = [];
      
      
      if (!this.files.length) return;
      for (let i = 0; i < this.files.length; i++) {
        
        readers.push(this.readAsDataURL(this.files[i]));
      }
      Promise.all(readers).then((values) => {
        this.files = [];
        values.forEach((file) => {
          this.input.base64 = file;
             var data = JSON.stringify(this.input);
                axios.post('/property/v1/upload_propertyimages/'+this.pid,data).then(res=>{
              this.getImages(file);
              //this.Imgs.push(file);
              // console.log('res',res);
             //  this.my_photo= ''
               //this.input.avatar_pathurl = '';
                // this.input.userFile = {};
              // this.getImages();

           }).catch(()=>{
           

         });
        
        });
      
        //this.Imgs = this.Imgs.concat(values);
        //this.Imgs =values;
      });
    },
    reset() {
      this.$refs.uploadInput.value = null;
      this.Imgs = [];
      this.files = [];
      this.$emit("changed", this.files);
    },
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  background: #f7fafc;
  border: 0.5px solid #a3a8b1;
  border-radius: 10px;
  padding: 30px;
  position: relative;
}
.drop {
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
  position: absolute;
  background-color: #f4f6ff;
  left: 0;
  border: 3px dashed #a3a8b1;
}
.error {
  text-align: center;
  color: red;
  font-size: 15px;
}
.beforeUpload {
  position: relative;
  text-align: center;
}
.beforeUpload input {
  width: 100%;
  margin: auto;
  height: 100%;
  opacity: 0;
  position: absolute;
  background: red;
  display: block;
}
.beforeUpload input:hover {
  cursor: pointer;
}
.beforeUpload .icon {
  width: 150px;
  margin: auto;
  display: block;
}
.imgsPreview .imageHolder {
  width: 150px;
  height: 150px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  margin: 5px 5px;
  display: inline-block;
}
.imgsPreview .imageHolder img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.imgsPreview .imageHolder .delete {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 29px;
  height: 29px;
  color: #fff;
  background: red;
  border-radius: 50%;
}
.imgsPreview .imageHolder .delete:hover {
  cursor: pointer;
}
.imgsPreview .imageHolder .delete .icon {
  width: 66%;
  height: 66%;
  display: block;
  margin: 4px auto;
}
.imgsPreview .imageHolder .plus {
  color: #2d3748;
  background: #f7fafc;
  border-radius: 50%;
  font-size: 21pt;
  height: 30px;
  width: 30px;
  text-align: center;
  border: 1px dashed;
  line-height: 23px;
  position: absolute;
  right: -42px;
  bottom: 43%;
}
.plus:hover {
  cursor: pointer;
}
.imgsPreview .imageHolder .check-cover {
  position: absolute;
  top: 4px;
  left: 10px;

  color: #fff;

  border-radius: 50%;
}
.clearButton {
  color: #2d3748;
  position: absolute;
  top: 7px;
  right: 7px;
  background: none;
  border: none;
  cursor: pointer;
}
</style>
