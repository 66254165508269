   
<template>
  <div>
     <vue-upload-images :max="20" :id="property_id" />
    <!--<b-row>
      <b-col md="12">
       <div class="border rounded p-2">
          
            <b-media
              no-body
              vertical-align="center"
            
              class="flex-column flex-md-row "
            >
              <b-media-aside>
                <b-img
                 id="profile"
                  ref="refPreviewEl"
                  :src="input.avatar_pathurl"
                  height="110"
                  width="170"
                  style="display:none;"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>

               
                
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    v-model="input.userFile"
                    accept=".jpg, .png, .gif"
                    placeholder="Choose file"
                    @input="inputImageRenderer"
                    drop-placeholder="Drop file here..."
                    
                  />
                </div>
                 <div class="d-inline-block">
                 <b-button
                            variant="primary"
                            type="submit"
                            @click.prevent="upload"
                        >
                            upload
                        </b-button>
                </div>
              </b-media-body>
            </b-media>
          </div>
      </b-col>

    
      <b-col md="12">
        <h6 class="text-primary font-weight-bold mb-2">
          List Image Property ID # {{ property_id }}
        </h6>

        
        <draggable
          :list="lists"
          tag="ul"
          group="people"
          :options="options" handle=".handle"
          class="list-group list-group-flush cursor-move"
          @change="moveImages"
        >
          <b-list-group-item
            v-for="(listItem, index) in lists"
            :key="index"
            tag="li"
          >
            <div class="d-flex drag-image">
              <feather-icon   class="handle size-25  mr-3" style="font-size:20px;" icon="MenuIcon" />
              
             <b-img
                :src="listItem.fullfilepath"
                class="mr-3"
                height="100"
                />
             
               <div  >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  @click="deleteconfirm(listItem.id)"
                >
                  Delete
                </b-button>
              </div>
            </div>
          </b-list-group-item>
        </draggable>
      </b-col>


    </b-row>-->
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import {
  BListGroupItem, BAvatar, BRow, BCol, BCardText, BImg, BFormCheckbox, BButton,BMedia,BMediaAside,BMediaBody,BFormFile,
  BLink
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import VueUploadImages from './UploadImage.vue'

export default {
  components: {
    BAvatar,
    BListGroupItem,
    BRow,
    BCol,
    Prism,
    draggable,
    BCardText,
    BImg,
    BFormCheckbox,
    BButton,
    BMedia,BMediaAside,BMediaBody,
    BFormFile,
    BLink,
    VueUploadImages
  },
  data() {
    return {
        property_id:router.currentRoute.params.id,
      lists: [],
      input:{
        userFile:[]
      },
        options: {
        handle: '.handle'
      }
     
    }
  },
  setup(){
     const refInputEl = ref(null)
    const refPreviewEl = ref(null)
     console.log('image',refInputEl)
     const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { 
       console.log('image',base64)
       refPreviewEl.value.src = base64 
       })
     
      return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }

  },
  created(){
   
      this.getImages();

  },
  directives: {
    Ripple,
  },
  methods: {
      /* moveImages(){
          var data = JSON.stringify(this.lists);
           axios.post('/property/v1/upadaete_propertyimages/'+this.property_id,data).then(res=>{
               console.log('res',res);
               this.getImages();

           }).catch(()=>{
           

         });
          
      },
      async upload(){
        if(this.input.userFile){
              this.input.base64 = document.getElementById('profile').src;
               var data = JSON.stringify(this.input);
               axios.post('/property/v1/upload_propertyimages/'+this.property_id,data).then(res=>{
               console.log('res',res);
               //this.input.avatar_pathurl = '';
                // this.input.userFile = {};
               this.getImages();

           }).catch(()=>{
           

         });
       }

      },
      deleteconfirm(id){
        this.$bvModal
          .msgBoxConfirm('Please confirm that you want to delete Image ID.'+id, {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if(value === true){
                 axios.delete('/property/v1/deletepropertyimages/'+id).then(res=>{
                      console.log('res',res);
             
                      this.getImages();

                  }).catch(()=>{
                  

                });
              //this.deleteData(id)
            }
            
          })
        
      },
      async getImages(){
          console.log('property_id',this.property_id);
       let images = await axios.get('/property/v1/getpropertyimages/'+this.property_id);
       if(images.data.data){
           this.lists = images.data.data;
       }
       console.log('images',images);
          //property/v1/getpropertyimages
      }*/
  }
}
</script>
<style>
.list-group-item {
  transition: all 1s
}
</style>
